import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
//@ts-ignore
import bigBadge1 from "./images/badges/big-badge1.png";
//@ts-ignore
import bigBadge2 from "./images/badges/big-badge2.png";
//@ts-ignore
import bigBadge3 from "./images/badges/big-badge3.png";
//@ts-ignore
import compnyLogo from "./images/details/c-logo.png";
import { redirect, useSearchParams, useParams, Link } from "react-router-dom";
import { IoChevronForwardSharp, IoChevronDownSharp } from "react-icons/io5";
import {
    ProductEvent,
  StallEvent,
  getCategoryForStall,
  getImageForStall,
  getStallsByPubkey,
  getProductsByAuthor
} from "./lib/nip15";
import { HARDCODED_STORES, MerchantStore, Product, ProductRecord, Stall, StallRecord, StoreTemplate } from "./stores";
import { clsx } from 'clsx';

export function StoreProduct({ product }: { product: Product }) {
    const { id, name, images,quantity, price, currency } = product;

    return (
        <Link to={`/details/${id}`} className="store-product">
            <div className="ProductPreviewSmall">
                <div
                    style={{
                        background: `url(${images[0]})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                    className="product-image"
                    alt={name}
                ></div>
                <div className="card-body">
                    <h4>{name}</h4>
                    <i className="card-subtitle mb-2 text-muted">ID: {id}</i>
                    <hr></hr>
                    <p className="card-text">Availability: {quantity}</p>
                    <i className="PricePreview">
                        {price} {currency}
                    </i>
                </div>
            </div>
        </Link>
    );
}

export function StoreStall({ stall, template }: { stall: Stall, template?: StoreTemplate }) {
    const { name, products, description, currency, shipping } = stall;

    switch (template) {
        default:
            const [open, setOpen] = useState<boolean>(true);

            return (
                <div className="store-stall">
                    <div 
                        className="store-stall-name"
                        onClick={() => setOpen(!open)}
                    >
                        <div className="store-stall-toggle">
                        {
                            open ? <IoChevronDownSharp /> : <IoChevronForwardSharp />
                        }
                        </div>
                        <h2>{name}</h2>
                        <span>
                        ({Object.values(products).length} products)
                        </span>
                    </div>
                    <div 
                        className={clsx("store-products", open ? "store-products-open" : "store-products-closed")}
                    >
                        {Object.values(products).map((product) => <StoreProduct key={`product-${product.id}`} product={product}/>)}
                    </div>
                </div>
            );
    }
}

export function StoreStalls({ stalls, template }: { stalls?: StallRecord, template?: StoreTemplate }) {

    switch (template) {
        default:
            return (
                <div className="store-stalls">
                    { stalls ?
                        Object.values(stalls).map((stall) => <StoreStall key={`stall-${stall.id}`} stall={stall} />) :
                        "Loading..."
                    }
                </div>
            );
    }
}

export function StoreHero({ store }: { store: MerchantStore }) {
    return (
        <div className="container">
            <div className="store-hero-wrapper">
                <div 
                    style={{
                        background: `url(${store.info.hero})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                    className="store-hero-bg"
                ></div>
                <div className="store-hero-mask">
                    <h1>Empowering Your Digital Lifestyle</h1>
                    <p>
                         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vestibulum purus vestibulum, sodales mi ac, molestie eros. Nullam pellentesque erat a vulputate suscipit.                 </p>
                </div>
            </div>
        </div>
    )
}

export function StoreNavbar({ store }: { store: MerchantStore }) {
    return (
        <div className="store-nav">
            <div className="container">
                <div className="store-nav-logo">
                    { 
                        store.info.navLogo ? 
                        <img src={store.info.navLogo} /> :
                        store.name
                    }
                </div>
                <div className="store-nav-btns">
                </div>
            </div>
        </div>
    )
}

export function StoreTemplateDefault({ store, stalls }: { store: MerchantStore, stalls: StallRecord }) {
    const { template } = store.info;

    return (
      <div className="store-wrapper">
        <StoreNavbar store={store} />
        <StoreHero store={store} />
        <div className="container store-primary">
            <StoreStalls stalls={stalls} template={template} />
        </div>
      </div>
    )
}

export function StoreTemplateTech({ store, stalls }: { store: MerchantStore, stalls: StallRecord }) {
    const { template } = store.info;

    return (
      <div className="store-wrapper">
        <StoreNavbar store={store} />
        <StoreHero store={store} />
        <div className="container store-primary">
            <StoreStalls stalls={stalls} template={template} />
        </div>
      </div>
    )
}

type StoreParams = {
  id: string
};

export default function Store() {
  const { id } = useParams<StoreParams>();
  const store = HARDCODED_STORES[id as string];

  const [stalls, setStalls] = useState<StallRecord>({});

  const updateState = (stall: Stall, _product?: Product) => {
    setStalls({
        ...stalls,
        [stall._raw_event.id]: stall,
    })
  }

  useEffect(() => {
    if (!id || !store) {
        redirect("/404");
        return;
    }

    store.fetchStalls({ withProducts: true, onNew: updateState })
    setStalls(store.stalls);
  });

  switch (store.info.template) {
    case StoreTemplate.TECH:
        return <StoreTemplateTech stalls={stalls} store={store} />
    default:
        return <StoreTemplateDefault stalls={stalls} store={store} />
  }
};
