import React from "react";
import { Link } from "react-router-dom";

function Store(props) {
  const { name, logo, info, pubKey } =
    props.store;
  let ratingArr = [];

  for (let i = 0; i < Math.floor(info.ratings / 100); i++) {
    ratingArr.push(
      <span>
        <i className="fa fa-star" aria-hidden="true"></i>
      </span>
    );
  }
  return (
    <Link
      to={`/store/${pubKey}`}
      className="p-box"
    >
      <span className="p-cat mb-0 d-block">{info.categories.join(', ')}</span>
      {info.isFeatured && (
        <span className="featured d-block">Featured</span>
      )}

      <div className="text-center">
        <img src={logo} alt="Product" />
      </div>
      <p>
        <strong>{name}</strong>
      </p>
      <div className="ratings">{ratingArr.map((rating) => rating)}</div>
    </Link>
  );
}

export default Store;
