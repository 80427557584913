import { Sub } from "nostr-tools";
import { ProductEvent, ShippingZone, StallEvent, getStallsByPubkey, getProductsByAuthor } from "./lib/nip15";

export type StallRecord = Record<string, Stall>;
export type ProductRecord = Record<string, Product>;

export class Product {
  constructor(
    public _raw_event: ProductEvent,
    public id: string,
    public name: string,
    public stall_id: string,
    public price: number,
    public quantity: number,
    public currency: "USD",
    public shipping: ShippingZone[],
    public description?: string,
    public images?: string[],
    public specs?: [string, string][],
    public categories?: string[],
  ) {}

  static fromEvent(p: ProductEvent) {
    return new Product(
      p,
      p.content.id,
      p.content.name,
      p.content.stall_id,
      p.content.price,
      p.content.quantity,
      p.content.currency,
      p.content.shipping,
      p.content.description,
      p.content.images,
      p.content.specs,
      p.content.categories
    )
  }
}

export class Stall {
  public products: ProductRecord = {};

  constructor(
    public _raw_event: StallEvent,
    public id: string,
    public name: string,
    public currency: "USD",
    public shipping: ShippingZone[],
    public description?: string,
  ) {};

  static fromEvent(s: StallEvent) {
    return new Stall(
      s,
      s.content.id,
      s.content.name,
      s.content.currency,
      s.content.shipping,
      s.content.description
    );
  }

  async fetchProducts(onNew?: (product: Product) => void) {
    return getProductsByAuthor(this._raw_event.pubkey, (productEv) => {
      if (this.products[productEv.id]) return;

      this.products = {
        [productEv.id]: Product.fromEvent(productEv),
        ...this.products,
      };

      onNew?.(this.products[productEv.id]);
    })
  }
}

export enum StoreTemplate {
  TECH,
  DEFAULT
}

export interface MerchantStoreInfo {
  hero: string;
  description: string;
  isFeatured: boolean;
  categories: string[];
  ratings: number;
  template?: StoreTemplate;
  navLogo?: string;
}

export class MerchantStore {
  public stalls: StallRecord = {};
  private sub: Sub<number>;

  constructor(
    public pubKey: string,
    public name: string,
    public logo: string,
    public info: MerchantStoreInfo
  ) {}

  // This is done in such a way that a product update/receive
  // does not trigger a stall's rerender
  async fetchStalls(
    options?: { 
      withProducts: boolean, 
      onNew: (stall: Stall, product?: Product) => void 
    }
  ) {
    this.sub = await getStallsByPubkey(this.pubKey, (stallEv) => {
      if (this.stalls[stallEv.id]) return;

      const stall = Stall.fromEvent(stallEv);
      this.stalls = {
        [stallEv.id]: stall,
        ...this.stalls,
      };

      if (options?.withProducts === true) 
        this.stalls[stallEv.id].fetchProducts((product) => options.onNew(stall, product));

      options?.onNew(stall);
    });

    return this;
  }

  unsub() {
    this.sub?.unsub();
    console.log("STORE UNSUBBED");
  }
}

export const HARDCODED_STORES: Record<string, MerchantStore> = Object.fromEntries(
  [
    new MerchantStore(
      'cc48ea4b7d16495cc47782dc369cb12a093ce3b152e7cfc8aa4cf63e2ecde673',
      'ElectroHub',
      '/static/media/electrohub.0714ac986d439bca0cfb.png',
      {
        description: "Your go-to for fast and efficient technology",
        ratings: 500,
        categories: ["Electronic"],
        isFeatured: true,
        hero: '/media/electrohub-hero.jpg',
        template: StoreTemplate.TECH,
        navLogo: '/media/electrohub-nav-logo.png',
      }
    ),
    new MerchantStore(
       'f6649d009e97aab43fa2a03f1eea170829553a273be5e603a87bef4510d1300e',
       'RAG Services',
       '/static/media/rag_services.6d4480ef2b74ca1e281b.png',
       {
        description: "Test description",
        ratings: 432,
        categories: ["Services"],
        isFeatured: true,
        hero: ''
       }
    )
  ].map((store) => [store.pubKey, store])
);
