import React, { useEffect, useState, useRef } from "react";
import { getStallsByPubkey, getProductsByAuthor } from "./lib/nip15";
import { submitProduct, submitStall, KeyGen, deleteProduct } from "./lib/product.ts";
import { AiFillAlert, AiFillCaretDown, AiFillDelete } from "react-icons/ai";
import "./styles/manage.css";

const ManageProduct = () => {
    const [privateKey, SetprivateKey] = useState("");
    const [publicKey, SetpublicKey] = useState("");
    //Stall list
    const [stalls, setStalls] = useState([]);
    //Prod list
    const [prods, setProds] = useState([]);
    //See page sections
    const [isOpenNewStall, SetIsOpenNewStall] = useState(false);
    const input_PrivateKey = useRef(null);
    const input_PublicKey = useRef(null);

    const onNewStallCallBack = (ns) => {
        if (stalls.find((ss) => ss.id === ns.content.id)) {
            getProductsByAuthor(ns.content.id, publicKey, (p) => {
                if (prods.find((ps) => ps.product.id === p.content.id)) {
                    return;
                }
                setProds((old) => {
                    return [
                        ...old,
                        {
                            product: p.content,
                            stallID: ns.content.id,
                        },
                    ];
                });
            });
            return;
        }
        setStalls((old) => {
            return [...old, ns.content];
        });

        getProductsByAuthor(ns.content.id, publicKey, (p) => {
            if (prods.find((ps) => ps.product.id === p.content.id)) {
                return;
            }
            setProds((old) => {
                return [
                    ...old,
                    {
                        product: p.content,
                        stallID: ns.content.id,
                    },
                ];
            });
        });
    };

    const handleGetStallsForPubKey = () => {
        if (publicKey && privateKey && publicKey.length > 12) {
            getStallsByPubkey(publicKey, onNewStallCallBack)
                .then((result) => {})
                .catch((error) => {});
        } else {
            alert("inserire chiave");
        }
    };

    return (
        <>
            <div className="managerContainer">
                <div className="managerHeader">
                    <h3>Manage Store</h3>
                    <div className="headerAction">
                        <div>Keys:</div>
                        <div>
                            <label>Private:</label>

                            <input
                                type="text"
                                ref={input_PrivateKey}
                                onChange={(e) => {
                                    SetprivateKey(e.target.value);
                                }}
                            />
                        </div>
                        <div>
                            <label>Store key:</label>

                            <input
                                type="text"
                                ref={input_PublicKey}
                                onChange={(e) => {
                                    SetpublicKey(e.target.value);
                                }}
                            />
                            <button onClick={handleGetStallsForPubKey} className="searchBtn btn btn-theme mt-3 mb-3">
                                Search
                            </button>
                        </div>
                        <button
                            onClick={async () => {
                                const result = await KeyGen();
                                SetprivateKey(result.priv);
                                SetpublicKey(result.pub);
                                input_PublicKey.current.value = result.pub;
                                input_PublicKey.current.disabled = true;
                                input_PrivateKey.current.value = result.priv;
                                input_PrivateKey.current.disabled = true;
                            }}
                            className="newKeys btn btn-theme mt-3 mb-3"
                        >
                            New Keys!
                        </button>
                    </div>
                    <p className="AlertRed">
                        <span className="IconBig">
                            <AiFillAlert></AiFillAlert>
                        </span>{" "}
                        we CANNOT recover your key right now: you lose them, you lose EVERYTHING: you shop, your stall, your orders etc,BEST thing to do is to
                        SAVE them somewhere safe
                    </p>
                    {publicKey && publicKey.length > 12 && (
                        <>
                            <hr />
                            <AddStall privatekey={privateKey} pubkey={publicKey} updateFunc={handleGetStallsForPubKey}></AddStall>
                            <hr />
                        </>
                    )}
                </div>

                {publicKey && publicKey.length > 12 && (
                    <>
                        {stalls &&
                            stalls.map((s) => {
                                return (
                                    <div className="SectionContainerBig">
                                        <div className="Header">
                                            <i>{s.id}</i>
                                            <h2>STALL: {s.name}</h2>
                                            <div style={{ marginTop: "20px" }} className="Shipping zone">
                                                <i style={{ float: "left" }}>
                                                    <b style={{ fontSize: "1rem", fontWeight: 600, color: "black" }}>Shipping zone</b>
                                                </i>
                                                <br></br>
                                                {s.shipping &&
                                                    s.shipping.map((s) => {
                                                        return <i style={{ float: "left" }}>{"Name: " + s.name + "  -  Cost: " + s.cost}</i>;
                                                    })}
                                            </div>
                                            <br></br>
                                            <button
                                                onClick={async () => {
                                                    const deleteData = {
                                                        stall_id: s.id,
                                                        name: s.name,
                                                        privateKey: privateKey,
                                                        publicKey: publicKey,
                                                    };

                                                    const data = await deleteProduct(deleteData);
                                                }}
                                                className="searchBtn btn btn-theme mt-3 mb-3"
                                            >
                                                Delete stall
                                            </button>
                                            <p>{s.description}</p>
                                        </div>

                                        <hr></hr>
                                        <div className="Body">
                                            <h3>Prodotti:</h3>
                                            <AddProd shippingZones={s.shipping} privatekey={privateKey} pubkey={publicKey} stallid={s.id}></AddProd>
                                            <div style={{}}>
                                                {prods &&
                                                    prods
                                                        .filter((p) => {
                                                            return p.stallID === s.id;
                                                        })
                                                        .map((p) => {
                                                            console.log("Prodotto", p);
                                                            return (
                                                                <div className="productPreview">
                                                                    <div>
                                                                        <h4>{p.product.name}</h4>
                                                                        <i>{p.product.id}</i>
                                                                        {p.product.images[0] && <img src={p.product.images[0]} alt={p.product.name}></img>}
                                                                        <i>{p.product.description}</i>
                                                                        <br></br>

                                                                        <br></br>
                                                                        <i>{p.product.quantity} qty</i>
                                                                        <br></br>
                                                                        <i>
                                                                            {p.product.price}
                                                                            {"   "} {p.product.currency}
                                                                        </i>
                                                                    </div>
                                                                    <hr></hr>
                                                                    <div>
                                                                        <button
                                                                            onClick={async () => {
                                                                                const deleteData = {
                                                                                    stall_id: p.stallID,
                                                                                    name: p.product.name,
                                                                                    privateKey: privateKey,
                                                                                    publicKey: publicKey,
                                                                                    prod_id: p.product.id,
                                                                                };

                                                                                const data = await deleteProduct(deleteData);
                                                                            }}
                                                                            className="searchBtn btn btn-theme mt-3 mb-3"
                                                                        >
                                                                            Delete Product
                                                                        </button>
                                                                        <button className="searchBtn btn btn-theme mt-3 mb-3">Edit Product</button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                        {stalls.length === 0 && <h5>No Stall! create yout first stall</h5>}
                    </>
                )}
            </div>
        </>
    );
};

const AddStall = ({ pubkey, privatekey, updateFunc }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [stallInfo, setStallInfo] = useState({ stallID: "", stallName: "" });
    const [form, setForm] = useState({
        privateKey: privatekey,
        publicKey: pubkey,
        stallName: "",
        stallDescription: "",
        shippingZones: [{ name: "", cost: "", regions: "" }],
    });

    const addShippingZone = () => {
        setForm({
            ...form,
            shippingZones: [...form.shippingZones, { name: "", cost: "", regions: "" }],
        });
    };

    const handleChangeStall = (e, index, field) => {
        const value = e.target.value;
        if (field !== "shippingZones") {
            setForm({
                ...form,
                [field]: value,
            });
        } else {
            const newShippingZones = [...form.shippingZones];
            newShippingZones[index][e.target.name] = value;
            setForm({
                ...form,
                shippingZones: newShippingZones,
            });
        }
    };

    const handleSubmitStall = async (e) => {
        e.preventDefault(); // Prevent default form submission
        // console.log(stallInfo);
        const data = await submitStall(form);
        setStallInfo(data);
        console.log(stallInfo);
        alert("Aggiunta Stall completata!");
        updateFunc();
    };
    return (
        <>
            <span
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                <AiFillCaretDown style={isOpen ? { marginRight: "10px" } : { marginRight: "10px", transform: "rotate(-90deg)" }}></AiFillCaretDown>
            </span>
            <i>Stall Insertion:</i>
            {isOpen && (
                <form className="FormContainer" onSubmit={handleSubmitStall}>
                    <div className="FormRaw">
                        <div className="FormField" style={{ width: "50%" }}>
                            <label>Private key</label>
                            <input type="text" placeholder="Private Key" value={form.privateKey} onChange={(e) => handleChangeStall(e, null, "privateKey")} />
                        </div>
                        <div className="FormField" style={{ width: "50%" }}>
                            <label>Public key</label>
                            <input type="text" placeholder="Public Key" value={form.publicKey} onChange={(e) => handleChangeStall(e, null, "publicKey")} />
                        </div>
                    </div>
                    <div className="FormRaw">
                        <div className="FormField" style={{ width: "50%" }}>
                            <label>Stall name</label>
                            <input type="text" placeholder="Stall Name" value={form.stallName} onChange={(e) => handleChangeStall(e, null, "stallName")} />
                        </div>
                        <div className="FormField" style={{ width: "50%" }}>
                            <label>description</label>
                            <input
                                type="text"
                                placeholder="Stall Description"
                                value={form.stallDescription}
                                onChange={(e) => handleChangeStall(e, null, "stallDescription")}
                            />
                        </div>
                    </div>
                    <hr />
                    <div>
                        Insert shipping zones below:
                        <br />
                        <br />
                        {form.shippingZones.map((zone, index) => (
                            <div className="FormRaw" key={index}>
                                <div className="FormField">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        value={zone.name}
                                        onChange={(e) => handleChangeStall(e, index, "shippingZones")}
                                    />
                                </div>
                                <div className="FormField">
                                    <label>Cost</label>
                                    <input
                                        type="number"
                                        name="cost"
                                        placeholder="Cost"
                                        value={zone.cost}
                                        onChange={(e) => handleChangeStall(e, index, "shippingZones")}
                                    />
                                </div>
                                <div className="FormField">
                                    <label style={{ width: "20%" }}>Regions</label>
                                    <input
                                        type="text"
                                        name="regions"
                                        placeholder="Regions (comma-separated)"
                                        value={zone.regions}
                                        onChange={(e) => handleChangeStall(e, index, "shippingZones")}
                                    />
                                </div>
                            </div>
                        ))}
                        <br />
                        <button style={{ padding: "5px 10px" }} className="searchBtn btn btn-theme mt-3 mb-3" type="button" onClick={addShippingZone}>
                            Add shipping zone
                        </button>
                    </div>
                    <div>
                        <button style={{ marginLeft: "0px" }} className="newKeys btn btn-theme mt-3 mb-3" type="submit">
                            Submit
                        </button>
                    </div>
                </form>
            )}

            {/* <div>
                <label>
                    Stall ID:
                    <b>{stallInfo.stallID}</b>
                    {stallInfo.stallID && (
                        <a href={"/stall?id=" + stallInfo.stallID} target="_blank" rel="noopener noreferrer">
                            Go to Stall
                        </a>
                    )}
                </label>
            </div> */}
        </>
    );
};

const AddProd = ({ pubkey, privatekey, stallid, shippingZones }) => {
    const [prodInfo, setProdInfo] = useState({ prodId: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [formData, setFormData] = useState({
        publicKey: pubkey,
        privateKey: privatekey,
        stall_id: stallid,
        name: "",
        price: "",
        quantity: "",
        images: [],
        specs: [],
        description: "",
        categories: [],
        shipping: [],
    });

    const handleInputChange = (e, key) => {
        setFormData({ ...formData, [key]: e.target.value });
    };

    const addZoneToArray = (id, cost) => {
        const newObj = { id: id, cost: cost };
        const arr = [...formData.shipping, newObj];
        setFormData({ ...formData, shipping: arr });
        shippingZones.find((x) => x.name == id).IsHidden = true;
    };

    const addElementToArray = (key) => {
        setFormData({ ...formData, [key]: [...formData[key], ""] });
    };

    const isHttpsLink = (link) => /^https:\/\//.test(link);

    const addSpecToArray = () => {
        const arr = [...formData.specs, ["", ""]];
        setFormData({ ...formData, specs: arr });
    };

    const handleArrayChange = (e, key, index, subIndex = null) => {
        const arr = [...formData[key]];

        if (subIndex !== null) {
            arr[index][subIndex] = e.target.value;
        } else {
            arr[index] = e.target.value;
        }

        setFormData({ ...formData, [key]: arr });
    };

    const handleSubmitProduct = async (e) => {
        e.preventDefault(); // Prevent default form submission

        const data = await submitProduct(formData);
        setProdInfo(data);
        console.log(prodInfo);
    };

    return (
        <>
            <div>
                <span
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                >
                    <AiFillCaretDown style={isOpen ? { marginRight: "10px" } : { marginRight: "10px", transform: "rotate(-90deg)" }}></AiFillCaretDown>
                </span>
                <i>Product Insertion:</i>
                <br />
                {isOpen && (
                    <form className="FormContainer" onSubmit={handleSubmitProduct}>
                        <div className="FormRaw">
                            <div className="FormField">
                                <label>Private Key:</label>
                                <input type="password" value={privatekey} />
                            </div>
                            <div className="FormField">
                                <label>Public Key:</label>
                                <input type="text" value={pubkey} />
                            </div>
                            <div className="FormField">
                                <label>Stall ID:</label>
                                <input type="text" value={stallid} />
                            </div>
                        </div>
                        <div className="FormRaw">
                            <div className="FormField">
                                <label>Name:</label>
                                <input type="text" onChange={(e) => handleInputChange(e, "name")} />
                            </div>
                            <div className="FormField">
                                <label>Price:</label>
                                <input type="number" onChange={(e) => handleInputChange(e, "price")} />
                            </div>
                            <div className="FormField">
                                <label>Quantity:</label>
                                <input type="number" onChange={(e) => handleInputChange(e, "quantity")} />
                            </div>
                        </div>
                        {shippingZones && (
                            <>
                                <br></br>
                                <div className="FormRaw">
                                    <i>
                                        <b>Shipping Zones (must be a subset of those of the stall):</b>
                                    </i>
                                </div>
                                {shippingZones.map((zone) => {
                                    if (!zone.IsHidden)
                                        return (
                                            <div className="FormRaw">
                                                <div className="FormField">
                                                    <label>Ship-Zone:</label>
                                                    <input type="text" disabled={true} value={zone.name + " - " + zone.cost} />
                                                </div>
                                                <div className="FormField">
                                                    <label>Add cost</label>
                                                    <input
                                                        type="number"
                                                        onChange={(e) => {
                                                            zone["AddCost"] = e.target.value;
                                                        }}
                                                    />
                                                </div>
                                                <div className="FormField">
                                                    {!formData.shipping.find((s) => {
                                                        return s.name === zone.name;
                                                    }) && (
                                                        <button
                                                            onClick={() => {
                                                                addZoneToArray(zone.name, zone.AddCost);
                                                            }}
                                                            className="searchBtn btn btn-theme mt-3 mb-3"
                                                        >
                                                            Add Shipping zone
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                })}
                            </>
                        )}
                        <hr></hr>
                        {formData.shipping &&
                            formData.shipping.map((spec, index) => (
                                <div className="FormRaw">
                                    <div className="FormField" style={{ width: "45%" }}>
                                        <label>Ship-Zone:</label>
                                        <input
                                            type="text"
                                            placeholder="One of the shipping zones of the stall"
                                            value={spec.id}
                                            onChange={(e) => handleArrayChange(e, "shipping", index, "id")}
                                        />
                                    </div>
                                    <div className="FormField" style={{ width: "45%" }}>
                                        <label>Additional cost:</label>
                                        <input
                                            type="number"
                                            placeholder="Additional cost of item"
                                            value={spec.cost}
                                            onChange={(e) => handleArrayChange(e, "shipping", index, "cost")}
                                        />
                                    </div>
                                    <div className="FormField" style={{ width: "10%" }}>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                console.log("shippingZones", shippingZones);
                                                shippingZones.find((x) => x.name == spec.id).IsHidden = false;
                                                const arr = formData.shipping.map((s) => {
                                                    if (s.id != spec.id) {
                                                        return s;
                                                    }
                                                });
                                                setFormData({ ...formData, shipping: arr });
                                            }}
                                            style={{ backgroundColor: "transparent", border: "none", fontSize: "1.5rem" }}
                                        >
                                            <AiFillDelete />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        <br />
                        <div className="FormRaw">
                            <div className="FormField" style={{ width: "100%" }}>
                                <label style={{ fontSize: "1.5rem", width: "10%" }}>Categories: </label>
                                <button type="button" className="SmallBtn" onClick={() => addElementToArray("categories")}>
                                    +
                                </button>
                            </div>
                        </div>
                        <hr />
                        {formData.categories.map((cat, index) => {
                            return (
                                <>
                                    <div className="FormRaw">
                                        <div className="FormField" style={{ width: "20%" }}>
                                            <input
                                                className="CatInput"
                                                key={index}
                                                type="text"
                                                value={cat}
                                                onChange={(e) => handleArrayChange(e, "categories", index)}
                                            />
                                        </div>
                                    </div>
                                    <br />
                                </>
                            );
                        })}
                        <br />

                        <div className="FormRaw">
                            <div className="FormField" style={{ width: "100%" }}>
                                <label style={{ fontSize: "1.5rem", width: "10%" }}>Specs: </label>
                                <button type="button" className="SmallBtn" onClick={addSpecToArray}>
                                    +
                                </button>
                            </div>
                        </div>
                        <hr />
                        {formData.specs.map((spec, index) => {
                            return (
                                <>
                                    <div key={index} className="FormRaw">
                                        <div className="FormField" style={{ width: "20%" }}>
                                            <input
                                                type="text"
                                                className="CatInput"
                                                placeholder="Spec type"
                                                value={spec[0]}
                                                onChange={(e) => handleArrayChange(e, "specs", index, 0)}
                                            />
                                        </div>
                                        <div className="FormField" style={{ width: "20%" }}>
                                            <input
                                                type="text"
                                                className="CatInput"
                                                placeholder="Spec value"
                                                value={spec[1]}
                                                onChange={(e) => handleArrayChange(e, "specs", index, 1)}
                                            />
                                        </div>
                                    </div>
                                    <br />
                                </>
                            );
                        })}
                        <br />

                        <div className="FormRaw">
                            <div className="FormField" style={{ width: "100%" }}>
                                <label style={{ fontSize: "1.5rem", width: "10%" }}>Images: </label>
                                <button type="button" className="SmallBtn" onClick={() => addElementToArray("images")}>
                                    +
                                </button>
                            </div>
                        </div>
                        <hr />
                        {formData.images.map((img, index) => {
                            return (
                                <>
                                    <div className="FormRaw">
                                        <div className="FormField" style={{ width: "80%" }}>
                                            <input type="text" className="CatInput" value={img} onChange={(e) => handleArrayChange(e, "images", index)} />
                                            {!isHttpsLink(img) && <label>Not a valid HTTPS link</label>}
                                        </div>
                                    </div>
                                    <br />
                                </>
                            );
                        })}
                        <br />

                        <button className="searchBtn btn btn-theme mt-3 mb-3" type="submit">
                            Submit
                        </button>
                    </form>
                )}
            </div>
            {/* <div>
                <label>
                    Product Id:
                    <b>{prodInfo.prodId}</b>
                    {prodInfo.prodId && (
                        <a href={"/details?id=" + prodInfo.prodId} target="_blank" rel="noopener noreferrer">
                            Go to Product
                        </a>
                    )}
                </label>
            </div> */}
        </>
    );
};

export default ManageProduct;
